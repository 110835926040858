import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Typography, useTheme } from '@mui/material';

// Themed paragraph component
const ThemedParagraph = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Typography
      gutterBottom
      variant="body1"
      component="p"
      lineHeight={1.8}
      mb={2}
      style={{
        fontFamily: 'Georgia, "Times New Roman", Times, serif',
        color: theme.palette.mode === 'dark' ? '#E4E4E4' : '#333',
        fontSize: '16px',
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

// Themed list component
const ThemedList = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Typography
      component="li"
      variant="body1"
      lineHeight={1.8}
      style={{
        fontFamily: 'Georgia, "Times New Roman", Times, serif',
        color: theme.palette.mode === 'dark' ? '#CFCFCF' : '#444',
        marginBottom: '8px',
        fontSize: '16px',
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

// Code component
const CodeBlock = ({ node, inline, className, children, ...props }) => {
  const theme = useTheme();
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      style={atomDark}
      language={match[1]}
      PreTag="div"
      {...props}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code
      className={className}
      style={{
        fontFamily: '"Source Code Pro", monospace',
        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#F5F5F5',
        color: theme.palette.mode === 'dark' ? '#E4E4E4' : '#D81B60',
        padding: inline ? '2px 4px' : undefined,
        borderRadius: '4px',
      }}
      {...props}
    >
      {children}
    </code>
  );
};
// Custom link component
const StyledLink = ({ children, href, ...props }) => {
  const theme = useTheme();
  return (
    <a
      href={href}
      style={{
        fontWeight: '500',
        transition: 'color 0.2s ease',
        fontFamily: 'Georgia, "Times New Roman", Times, serif',
      }}
      {...props}
    >
      {children}
    </a>
  );
};
// Blockquote component
const Blockquote = ({ children }) => {
  const theme = useTheme();
  return (
    <blockquote
      style={{
        marginLeft: '1.5rem',
        marginBottom: '1rem',
        paddingLeft: '1rem',
        borderLeft: '4px solid',
        borderColor: theme.palette.mode === 'dark' ? '#5E5E5E' : '#DDD',
        color: theme.palette.mode === 'dark' ? '#CFCFCF' : '#555',
        fontStyle: 'italic',
      }}
    >
      {children}
    </blockquote>
  );
};

// Styled headers
const StyledHeader = ({ children, level }) => {
  const theme = useTheme();
  const styles = {
    h1: { fontSize: '2.5rem', marginTop: '2rem', marginBottom: '1.5rem' },
    h2: { fontSize: '2rem', marginTop: '1.8rem', marginBottom: '1.2rem' },
    h3: { fontSize: '1.75rem', marginTop: '1.5rem', marginBottom: '1rem' },
    h4: { fontSize: '1.5rem', marginTop: '1.2rem', marginBottom: '0.8rem' },
    h5: { fontSize: '1.25rem', marginTop: '1rem', marginBottom: '0.5rem' },
  };
  return (
    <Typography
      gutterBottom
      variant={`h${level}`}
      component={`h${level}`}
      style={{
        fontFamily: 'Georgia, "Times New Roman", Times, serif',
        fontWeight: 'bold',
        ...styles[`h${level}`],
        color: theme.palette.mode === 'dark' ? '#E4E4E4' : '#222',
        lineHeight: 1.4,
      }}
    >
      {children}
    </Typography>
  );
};

const components = {
  h1: (props) => <StyledHeader {...props} level={1} />,
  h2: (props) => <StyledHeader {...props} level={2} />,
  h3: (props) => <StyledHeader {...props} level={3} />,
  h4: (props) => <StyledHeader {...props} level={4} />,
  h5: (props) => <StyledHeader {...props} level={5} />,
  p: ThemedParagraph,
  li: ThemedList,
  ul: ({ children }) => (
    <ul style={{ paddingLeft: '1.5rem', marginBottom: '1rem' }}>{children}</ul>
  ),
  ol: ({ children }) => (
    <ol style={{ paddingLeft: '1.5rem', marginBottom: '1rem' }}>{children}</ol>
  ),
  code: CodeBlock,
  blockquote: Blockquote,
  a: StyledLink, // Use StyledLink for links
};

export { components };
