import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Typography, Box, Container, useTheme } from '@mui/material';

const Intro = () => {
  const theme = useTheme();

  return (
    <Box
      id="intro"
      className="hero-section"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
      }}
    >
      {/* Subtle decorative element */}
      <Box
        sx={{
          position: 'absolute',
          top: -100,
          right: -100,
          width: '400px',
          height: '400px',
          borderRadius: '50%',
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(165, 42, 42, 0.1)'
              : 'rgba(165, 42, 42, 0.03)',
          filter: 'blur(60px)',
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 2, md: 3 },
            maxWidth: '800px',
            mx: { xs: 'auto', md: '0' },
            px: { xs: 3, md: 6 },
            py: { xs: 4, md: 6 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: 'brown',
              position: 'relative',
              display: 'inline-block',
              width: 'fit-content',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -4,
                left: 0,
                width: '50%',
                height: '2px',
                backgroundColor: 'brown',
                opacity: theme.palette.mode === 'dark' ? 0.5 : 0.3,
              },
            }}
          >
            Hi, I'm
          </Typography>

          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: '2.5rem', md: '4.5rem' },
              letterSpacing: '-0.02em',
              color: 'text.primary',
              lineHeight: 1.1,
            }}
          >
            Kingsley Okon
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontWeight: 500,
              fontSize: { xs: '1.5rem', md: '2.25rem' },
              color: 'text.primary',
              letterSpacing: '-0.01em',
            }}
          >
            A Software Engineer
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 300,
              color: 'text.secondary',
              maxWidth: '600px',
              fontSize: { xs: '1rem', md: '1.25rem' },
              lineHeight: 1.6,
              mt: { xs: 1, md: 2 },
            }}
          >
            Specializing in web development & cybersecurity
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              mt: { xs: 3, md: 4 },
            }}
          >
            {['github', 'linkedin', 'resume'].map((platform) => (
              <Box
                key={platform}
                sx={{
                  position: 'relative',
                  '&:hover': {
                    '& > a': {
                      transform: 'translateY(-2px)',
                      color: 'brown',
                    },
                  },
                }}
              >
                <a
                  href={
                    platform === 'github'
                      ? 'https://github.com/kingsley9'
                      : platform === 'linkedin'
                      ? 'https://www.linkedin.com/in/kingsley-okon-190164221/'
                      : "/assets/Kingsley_Okon's_resume.pdf"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                    transition: 'all 0.2s ease-in-out',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={
                    platform === 'resume'
                      ? (e) => {
                          e.preventDefault();
                          window.location.href =
                            e.currentTarget.getAttribute('href');
                        }
                      : undefined
                  }
                >
                  {platform === 'github' ? (
                    <BsGithub size={24} />
                  ) : platform === 'linkedin' ? (
                    <BsLinkedin size={24} />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                      }}
                    >
                      Resume
                    </Typography>
                  )}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          position: 'absolute',
          bottom: { xs: 24, md: 40 },
          left: '50%',
          transform: 'translateX(-50%)',
          animation: 'bounce 2s infinite ease-in-out',
          '@keyframes bounce': {
            '0%, 100%': {
              transform: 'translate(-50%, 0)',
            },
            '50%': {
              transform: 'translate(-50%, 10px)',
            },
          },
        }}
      >
        <a
          href="#projects"
          style={{
            color: theme.palette.text.primary,
            transition: 'color 0.2s ease-in-out',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = 'brown')}
          onMouseLeave={(e) =>
            (e.currentTarget.style.color = theme.palette.text.primary)
          }
        >
          <KeyboardArrowDown sx={{ fontSize: { xs: 32, md: 40 } }} />
        </a>
      </Box>
    </Box>
  );
};

export default Intro;
