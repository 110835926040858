import React from 'react';

const SpotifyEmbed = ({ src, height = '352', style = {} }) => {
  const iframeStyle = {
    borderRadius: '12px',
    marginBottom: '15px',
    width: '100%',
    ...style,
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe
        style={iframeStyle}
        src={src}
        height={height}
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Spotify Embed"
      ></iframe>
    </div>
  );
};

export default SpotifyEmbed;
