import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import {
  db,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
} from '../../util/firebase';
import { components } from './MarkdownComponents';

const BlogPost = ({ id, content }) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    let isMounted = true;

    const incrementViewCount = async () => {
      try {
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const fromShare = urlParams.get('from');

        // Generate a unique session key for this post
        const sessionKey = `viewed_post_${id}`;
        const hasViewedThisSession = sessionStorage.getItem(sessionKey);

        // Count view if:
        // 1. Haven't viewed in this session OR
        // 2. Coming from a share link
        const shouldCountView = !hasViewedThisSession || fromShare;

        if (shouldCountView && isMounted) {
          // Mark as viewed in this session
          sessionStorage.setItem(sessionKey, 'true');

          // Update view count in Firebase
          const postDocRef = doc(db, 'posts', id);
          const postDoc = await getDoc(postDocRef);

          if (postDoc.exists()) {
            await updateDoc(postDocRef, {
              views: increment(1),
              lastUpdated: Date.now(),
            });
          } else {
            await setDoc(
              postDocRef,
              {
                views: 1,
                lastUpdated: Date.now(),
              },
              { merge: true }
            );
          }
        }
      } catch (error) {
        console.error('Error updating view count: ', error);
      }
    };

    incrementViewCount();

    return () => {
      isMounted = false;
    };
  }, [id]);

  return (
    <Box>
      <ReactMarkdown components={components} className="markdown">
        {content}
      </ReactMarkdown>
    </Box>
  );
};

export default BlogPost;
