import React, { useState, useRef } from 'react';
import { Typography } from '@mui/material';
import emailjs from 'emailjs-com';
import './contact.scss';

function Contact() {
  const form = useRef();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const msg = { email, message };
      emailjs.init('XaePIeTAi7kCXXA9V');
      await emailjs.send('service_e0h05iv', 'template_u388ojk', msg);
      setMessage('');
      setEmail('');
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 5000);
    } catch (err) {
      console.error(err.message);
      alert('There was an error sending your message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact" id="contact">
      <div className="wrapper">
        <div className="title">
          <Typography variant="h3" component="h2" fontWeight="bold">
            Contact Me
          </Typography>
        </div>

        <form ref={form} onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="email"
              required
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isSubmitting}
            />
          </div>

          <div className="input-container">
            <textarea
              placeholder="Your Message"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={isSubmitting}
            />
          </div>

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>

          {submitted && (
            <div className="success-message">
              Thanks! I'll get back to you ASAP!
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Contact;
