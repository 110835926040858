import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { db, doc, getDoc } from '../../util/firebase';
import BlogPost from '../../components/blog/BlogPost';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDarkMode } from '../../contexts/DarkModeContext';
import MetaTags from '../../components/blog/MetaTags'; // Add this import

const useStyles = makeStyles((theme) => ({
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3),
  },
  cardMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1rem',
    color: 'gray',
    paddingLeft: 2,
    paddingRight: 10,
    paddingBottom: theme.spacing(1),
  },
  blogsContainer: {
    padding: theme.spacing(10),
    width: '70%',
    paddingBottom: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(10),
    },
  },
}));

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { darkMode } = useDarkMode();
  const DEFAULT_IMAGE =
    'https://images.unsplash.com/photo-1599081595476-75608b796d52?w=840&h=500&fit=crop&q=80';

  useEffect(() => {
    const fetchPostFromMarkdown = async () => {
      try {
        const file = await import(`../..//posts/${id}.md`);
        const res = await fetch(file.default);
        const text = await res.text();
        const [_, frontmatter, content] = text.split('---');

        // Improved metadata parsing
        const metadata = frontmatter.split('\n').reduce((acc, line) => {
          if (line.trim()) {
            const colonIndex = line.indexOf(':');
            if (colonIndex !== -1) {
              const key = line.slice(0, colonIndex).trim();
              const value = line.slice(colonIndex + 1).trim();
              acc[key] = value;
            }
          }
          return acc;
        }, {});

        // Calculate reading time
        const wordsPerMinute = 200; // Average reading speed
        const wordCount = content.split(/\s+/).length;
        const readingTime = Math.ceil(wordCount / wordsPerMinute);

        const postDocRef = doc(db, 'posts', id);
        const postDoc = await getDoc(postDocRef);
        const views = postDoc.exists() ? postDoc.data().views : 0;

        const postData = { ...metadata, content, views, readingTime };
        setPost(postData);
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostFromMarkdown();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }
  return (
    <>
      <MetaTags
        title={post.title}
        description={post.description}
        image={post.image}
        keywords={post.keywords}
        date={post.date}
        category={post.category}
        readingTime={`${post.readingTime} min read`} // Add reading time
      />

      <div className="container">
        <Container maxWidth="md" className={classes.blogsContainer}>
          <Box
            my={2}
            component={Link}
            to="/blog"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: darkMode ? 'white' : 'black',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <ArrowBackIcon />

            <Typography
              variant="button"
              mx={1}
              style={{
                cursor: 'pointer',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
              }}
            >
              Back to Blog
            </Typography>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 800,
              fontFamily: 'Georgia, "Times New Roman", Times, serif',
            }}
            className={classes.blogTitle}
          >
            {post.title}
          </Typography>
          <Box className={classes.cardMeta}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '0.25rem',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: '500',
                  fontSize: { xs: '12px', md: '14px', lg: '15px' },
                  color: darkMode ? '#CFCFCF' : '#333',
                  fontFamily: 'Georgia, "Times New Roman", Times, serif',
                }}
              >
                {post.readingTime} min read
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'light',
                  color: darkMode ? '#888' : '#BBB',
                  margin: '0 4px',
                  fontFamily: 'Georgia, "Times New Roman", Times, serif',
                }}
              >
                &middot;
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: '400',
                  fontSize: { xs: '12px', md: '14px', lg: '15px' },
                  fontStyle: 'italic',
                  color: darkMode ? '#AAAAAA' : '#555',
                  fontFamily: 'Georgia, "Times New Roman", Times, serif',
                }}
              >
                {post.date}
              </Typography>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: '400',
                fontSize: { xs: '12px', md: '13px', lg: '14px' },
                marginTop: '0.25rem',
                color: darkMode ? '#CFCFCF' : '#666',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
                whiteSpace: 'nowrap',
              }}
            >
              {post.views} views
            </Typography>
          </Box>

          <BlogPost id={id} content={post.content} />
          <Box mt={2}>
            <Typography
              variant="button"
              component={Link}
              to="/blog"
              style={{
                cursor: 'pointer',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
              }}
            >
              Back to all posts
            </Typography>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default BlogPostPage;
